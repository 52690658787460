<template>
  <Form type="login"></Form>
</template>
 
<script>
//引入注册组件
import Form from '../components/Form';
export default {
  name: "Login",
    components:{
      Form
    },
  data() {
    return{
      
    }
  },
 
  
};
</script>
 
